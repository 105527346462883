const env = {
  VITE_ENVIRONMENT: "${VITE_ENVIRONMENT}",
};

export const getEnv = () => {
  return {
    VITE_ENVIRONMENT: !env.VITE_ENVIRONMENT.includes("VITE_")
      ? env.VITE_ENVIRONMENT
      : import.meta.env.VITE_ENVIRONMENT,
  };
};
